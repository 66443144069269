import groupBy from 'lodash.groupby';
import { Event, EventOrSection } from './../components/Event';
import { Event as IEvent } from '../components/members/NewApiTypes.generated';
import pluralize from 'pluralize';
import { uniq, sortBy } from 'lodash';
import { differenceInCalendarDays, format } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import {
  GroupSectionStaffDeveloper,
  GroupSectionStaffDeveloperDate,
  PackageRequest,
  PackageRequestSchoolBasedPDSession,
  PackageSchoolBasedPDSession,
  User
} from '../components/members/NewApiTypes.generated';

function isValidDate(d: Date) {
  return d instanceof Date && !isNaN(d.getTime());
}

export const formatDate = (date: Date, shortDate: boolean = false) => {
  if (!isValidDate(date)) {
    throw new Error('Invalid date');
  }
  let options = {};
  if (shortDate) {
    options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    };
  } else {
    options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    };
  }
  return date.toLocaleDateString('en-US', {
    ...options,
    timeZone: 'America/New_York'
  });
};

export const formatDateWithoutTimezone = (
  date: Date,
  shortDate: boolean = false
) => {
  if (!isValidDate(date)) {
    throw new Error('Invalid date');
  }
  let options = {};
  if (shortDate) {
    options = {
      timeZone: 'UTC',
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    };
  } else {
    options = {
      timeZone: 'UTC',
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    };
  }
  return date.toLocaleDateString('en-US', {
    ...options
  });
};

type Edge = {
  node: any[];
};

type ContentfulContent = {
  edges: Edge[];
};
export const extractContent = (content: ContentfulContent): any[] => {
  return content.edges.map((e: Edge) => e.node);
};

export const getEventDateRange = (startDate: Date, endDate: Date): string => {
  const formattedStartDate = formatDate(startDate, true);
  const formattedEndDate = formatDate(endDate, true);

  if (formattedStartDate === formattedEndDate) {
    return formattedStartDate;
  }
  return `${formattedStartDate} - ${formattedEndDate}`;
};

export const groupEventsByMonth = (events: Event[] | EventOrSection[]) => {
  const eventsWithMonth = sortBy(events, 'startDate').map(
    (e: Event | EventOrSection) => {
      return {
        ...e,
        month: new Date(e.startDate).toLocaleDateString('en-US', {
          month: 'long'
        })
      };
    }
  );
  const grouped = groupBy(eventsWithMonth, 'month');
  const keys = Object.keys(grouped);
  return keys.map(k => {
    return {
      month: k,
      events: grouped[k]
    };
  });
};

export const formatPhoneNumber = (phoneNumberString: string): string => {
  if (!phoneNumberString) {
    return '';
  }
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return '';
};

export const formatAsCurrency = (value: number): string => {
  if (isNaN(value)) {
    return '--';
  }
  return `$${value
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
};

export const getManageServiceRoute = (
  packageRequestId: string,
  route: string
) => {
  return `/members/manage-services/package-request/${packageRequestId}/${route}`;
};

export const formatDateOfBirth = (date: string) => {
  if (!date) return;
  const dateString = date.slice(0, 10);
  const splitDate = dateString.split('-');
  return `${splitDate[1]}/${splitDate[2]}/${splitDate[0]}`;
};

export function getConnectLink(toolkitGoogleDriveId: string, user: User) {
  const userId = String((user as any)?.portalMetata?.userId || user.id);
  const userFullName = [user.firstName, user.lastName]
    .filter(Boolean)
    .join(' ');
  const userEmail = user.email;

  return process.env.GATSBY_CONNECT_DOWNLOAD_LINK
    ? `${process.env.GATSBY_CONNECT_DOWNLOAD_LINK}?google_drive_document_id=${toolkitGoogleDriveId}&user_email=${userEmail}&user_name=${userFullName}`
    : `https://drive.google.com/file/d/${toolkitGoogleDriveId}`;
  // return `https://drive.google.com/file/d/${toolkitGoogleDriveId}`;
}

export function makeAddressForDisplay(organization: any) {
  const {
    addressLine1,
    addressLine2,
    city,
    stateProvince,
    zipPostal,
    country
  } = organization;

  const orderedParts = [
    addressLine1,
    addressLine2,
    city,
    stateProvince,
    zipPostal,
    country
  ];

  return `${orderedParts.filter(p => !!p).join(', ')}`;
}

export const fillRange = (start: number, stop: number, interval: number = 1) =>
  Array.from(
    { length: (stop - start) / interval + 1 },
    (_, i) => start + interval * i
  );

export const fillDaySelection = (
  minDays: number,
  maxDays: number,
  selectionInterval: number,
  allowZeroSelection: boolean
) => {
  const range = fillRange(minDays, maxDays, selectionInterval);

  if (minDays > 0 && allowZeroSelection) {
    range.unshift(0);
  }

  return range.map(v => ({
    value: v.toString(),
    label: `${v} ${pluralize('Days', v)}`
  }));
};

type PdSessionSelection = {
  sessionId: string;
  requestedDays: number;
};

type StaffDeveloperPrices = {
  senior: number;
  regular: number;
};

const getLowAndHighCostsForPDSession = (
  packageRequest: PackageRequest,
  pdSession: PackageSchoolBasedPDSession
) => {
  const allCosts = [
    pdSession.overrideStaffDeveloperFees
      ? pdSession.staffDeveloperDayFee
      : packageRequest.package.staffDeveloperDayFee,
    pdSession.overrideStaffDeveloperFees
      ? pdSession.seniorStaffDeveloperDayFee
      : packageRequest.package.seniorStaffDeveloperDayFee,
    pdSession.overrideStaffDeveloperFees
      ? pdSession.projectLeaderDayFee
      : packageRequest.package.projectLeaderDayFee
  ];

  const existingCosts: number[] = allCosts.filter(Boolean) as number[];

  return existingCosts.sort((a, b) => a - b);
};

export const calculatePdSessionCostRange = (
  packageRequest: PackageRequest,
  pdSessionSelections: Partial<PackageRequestSchoolBasedPDSession>[],
  targetSession: PackageSchoolBasedPDSession
): [number, number] => {
  const matchedSession = pdSessionSelections.find(
    s => s.packageSchoolBasedPDSessionId === targetSession.id
  );

  if (!matchedSession) {
    return [0, 0];
  }

  const costs = getLowAndHighCostsForPDSession(packageRequest, targetSession);

  const days =
    (matchedSession.overrideDate
      ? matchedSession.overrideAmount
      : matchedSession.requestedDays) || 0;

  return [
    ((costs[0] || 0) / 100) * days,
    ((costs[costs.length - 1] || 0) / 100) * days
  ];
};

export const getUniqueStaffDeveloperGroupSectionDates = (
  groupSectionStaffDevelopers: GroupSectionStaffDeveloper[]
): string[] => {
  const allDates = flatPolyfill(groupSectionStaffDevelopers.map(s => s.dates))
    .map(
      // @ts-ignore
      (d: GroupSectionStaffDeveloperDate) => new Date(d.date)
    )
    .sort((a: Date, b: Date) => a.getTime() - b.getTime())
    .map((d: Date) => format(zonedTimeToUtc(d, 'UTC'), 'MMM d, yyyy'));

  const uniqueDates = uniq(allDates) as string[];
  return uniqueDates;
};

export const getGroupSectionStaffDevelopers = (
  groupSectionStaffDevelopers: GroupSectionStaffDeveloper[]
) => {
  return groupSectionStaffDevelopers
    .map(s => s.staffDeveloper)
    .map(s => `${s.firstName} ${s.lastName}`);
};

export const getFirstAndLastDate = (eventDates: string[]) => {
  const format = (date: Date, shortDate?: boolean) => {
    let s = null;

    try {
      s = formatDateWithoutTimezone(date, shortDate);
    } catch (e) {
      console.error('getFirstAndLastDate error: ', e);
      s = 'N/A';
    }

    return s;
  };

  const sortedDates = eventDates
    .map(d => new Date(d))
    .sort((a, b) => a.getTime() - b.getTime());

  return [
    format(sortedDates[0], true),
    format(sortedDates[sortedDates.length - 1], true)
  ];
};

export const flatPolyfill = <T>(arr: T[][], depth?: number) => {
  // If no depth is specified, default to 1
  if (depth === undefined) {
    depth = 1;
  }

  // Recursively reduce sub-arrays to the specified depth
  const flatten = function(arr: any[], depth: number): T[] {
    // If depth is 0, return the array as-is
    if (depth < 1) {
      return arr.slice();
    }

    // Otherwise, concatenate into the parent array
    return arr.reduce(function(acc, val) {
      return acc.concat(Array.isArray(val) ? flatten(val, depth - 1) : val);
    }, []);
  };

  return flatten(arr, depth);
};

export const isEventDeadlineOver = (event: IEvent) => {
  if (!event) return false;
  const deadline = new Date(event.finance?.deadline as string);
  if (!deadline) return false;
  return differenceInCalendarDays(new Date(), deadline) > 0;
};
